

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.container {
  display: flex;
  align-items: end;
  flex-direction: column;
  /* float: right; */
}
 .note-box-container {
  background: white;
  /* margin-top: 10px; */
  /* width: 31%; */
  position: relative;
  
  /* margin-left: 548px; */
  border-radius: 9px;
  /* display: none; */
  /* padding-top: 6px; */
  justify-content: space-between;
   /* padding: 0px 5px ;  */
  cursor: default;
  
  /* height: 124px; */
  /* top: -13px; */
  /* left: 450px; */
  /* width: 100%; */
  /* height: 100%; */
  /* top: 2px; */
  /* left: 5px; */
  border-radius: 10px;
  border: 1px;
  /* padding: 10px; */
  /* margin: 10px; */
  margin-top: 10px;
  margin-bottom: 10px;


}


.open_note_box {
  /* padding: 7px 7px; */
  align-items: center;
  /* position: absolute; */
  background-color: #fff;
   display: flex; 
  /* margin-top: 4px; */
  cursor: pointer;
  width:100%;
  height: 100%;
  top: 4px;
  left: 630px;
  /* border-radius: 20px;
  border: 1px solid #D9D9D9;
  box-shadow: 0px 4px 4px 0px #00000040; */



}

.note-arrow {
  transform: rotate(44deg);
  margin-left: 4%;
  border: solid #000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  width: 4%;
  height: auto;

}
.note-box-arrow{
  /* padding: -3px;
  margin-top: 9px;
  padding-top: 14px;
  transform: rotate(44deg);
  margin-left: 6px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  height: auto; */
  position: sticky;
  cursor: pointer;
}

.note-text {
  overflow: hidden;
  background-color: #fff;
  height: 93px;
  border-radius: 0px 0px 9px 9px;
  position: absolute;
  display: none;
  margin-left: -8px;
  outline: none;
  resize: none;
  resize: none;
  font-size: 15px;
  word-wrap: unset;
  white-space: nowrap;
  overflow-x: hidden;
  padding: 15px;
  /* top: 29px; */
  width: -webkit-fill-available;
  
}

.note_textarea:hover {
  outline: none !important;
  background-color: #fff;
  cursor: initial;
  /* border: none !important; */
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;

}

.note_textarea:focus {
  outline: none !important;
  background-color: #fff;
  cursor: initial;
  /* border: none !important; */
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;

}

.note_textarea::-webkit-scrollbar {
  width: 5px;
  height: 20px;
  border-radius: 5px;
  cursor: pointer;
  
}

/* .note_textarea::-webkit-scrollbar-track {
  background-color: rgb(243, 240, 240);
} */

.note_textarea::-webkit-scrollbar-thumb {
  background-color:  rgb(110, 110, 139);
  border-radius: 10px;
}

 
.private_note2_save{
     margin-right: 10px;
     background-color: rgb(25, 145, 235);
     border: none;
     color: white;
     /* display: none; */
     /* margin-left: 21px; */
     width: 55px;
     height: 22px;
     top: 0px;
     /* left: 180px; */
     border-radius: 4.236111164093018px;
     /* padding: 1px; */
     /* font-family: Inter; */
     font-size: 15px;
     font-weight: 500;
     line-height: 12px;
     letter-spacing: 0em;
     text-align: center;
     padding: 4px 4px;
     cursor: pointer;
     /* position: absolute; */
     float: right;
}
.private_note2_cancel{
  
  
  /* margin-left: -64px; */
  border-radius: 4.236111164093018px;
  border: 0.8472222089767456px;
  border: 0.85px solid #2798EC;
  /* font-family: Inter; */
  
  /* padding: 1px; */
  color: #2798EC;
  margin-right: 3px;
  /* background-color: rgb(25, 145, 235); */
  /* border: 19.85px; */
  /* color: white; */
  /* display: none; */
  /* margin-left: 110px; */
  width: 55px;
  height: 22px;
  top: 0px;
  /* left: 233px; */
  border-radius: 4.236111164093018px;
  /* padding: 1px; */
  /* font-family: Inter; */
  font-size: 15px;
  font-weight: 506;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  padding: 4px 4px;
  cursor: pointer;
  /* position: absolute; */
  float: right;
}

.Edit_note2_save{
  margin-right: 10px;
  /* margin-right: 33px; */
  background-color: rgb(25, 145, 235);
  border: none;
  color: white;
  /* display: none; */
  /* margin-left: 21px; */
  width: 55px;
  height: 22px;
  top: 0px;
  /* left: 450px; */
  border-radius: 4.236111164093018px;
  /* padding: 1px; */
  /* font-family: Inter; */
  font-size: 15px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  padding: 4px 4px;
  cursor: pointer;
  /* position: absolute; */
  float: right;
}
.Edit_note2_cancel{
  margin-right: 2px;
  
  /* margin-left: -64px; */
  border-radius: 4.236111164093018px;
  border: 0.8472222089767456px;
  border: 0.85px solid #2798EC;
  /* font-family: Inter; */
  
  /* padding: 1px; */
  color: #2798EC;
  /* margin-right: 33px; */
  /* background-color: rgb(25, 145, 235); */
  /* border: 19.85px; */
  /* color: white; */
  /* display: none; */
  /* margin-left: 110px; */
  width: 55px;
  height: 22px;
  top: 0px;
  /* left: 510px; */
  border-radius: 4.236111164093018px;
  /* padding: 1px; */
  /* font-family: Inter; */
  font-size: 15px;
  font-weight: 506;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  padding: 4px 4px;
  cursor: pointer;
  /* position: absolute; */
  float: right;
}

.note-text::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

.note-text::-webkit-scrollbar-track {
  background: #f1f1f1;

}

.note-text::-webkit-scrollbar-thumb {
  background: #888;

}

.note-text::-webkit-scrollbar-thumb:hover {
  background: #555;

}

/* Define the styles for the scrollbar */
textarea::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Define the track (background) of the scrollbar */
textarea::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
}

/* Define the thumb (the draggable part) of the scrollbar */
textarea::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

/* Define the hover effect for the scrollbar thumb */
textarea::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering over the thumb */
}
/* In this example:

::-webkit-scrollbar is used to target the scrollbar itself.
::-webkit-scrollbar-track defines the background color of the scrollbar's track (the area behind the thumb).
::-webkit-scrollbar-thumb defines the appearance of the scrollbar's thumb (the draggable part).
::-webkit-scrollbar-thumb:hover specifies the appearance when hovering over the thumb.
Keep in mind that these styles are specific to WebKit-based browsers (such as Chrome and Safari). To apply similar styles to other browsers, you would need to use vendor-specific prefixes for Firefox and Edge, like ::-webkit-scrollbar for WebKit, ::-webkit-scrollbar for Firefox, and -ms-overflow-style for Edge.

Here's how you can apply scrollbar styles for Firefox and Edge:

css
Copy code */
/* For Firefox */
textarea {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* For Edge */
textarea {
  -ms-overflow-style: none;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* These CSS rules set the width, colors, and other styles for the scrollbar in Firefox and Edge.

Please note that scrollbar styling in CSS is not universally supported and may not work in all browsers. Additionally, these styles may not work in some browser extensions or on mobile devices.
 Always test your styles across different browsers to ensure compatibility. */


 