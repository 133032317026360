.dropdown {
    position: absolute;
    padding: 2px;
    border-radius: 7px;
    /* margin-left: 17px; */
    z-index: 5;
    color: #fff;
  }
  
  
  .dropdown-toggle {
    display: flex;
    position: absolute;
    width: 189px;
    padding: 4px 6px;
    border-radius: 7px;
    /* margin-left: 17px; */
    z-index: 5;
    color: #fff;
    margin-top: 3px;
    background: #fff;
    justify-content: flex-start;
    height: auto;
    border: none;
  }
  
  /* .dropdown-toggle .approch-class {
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.4px;
    font-size: 14px;
    color: blue;
    padding-left: 2px;
    cursor: default;
  
  } */
  
  .approch-class::first-letter {
    font-size: 18px;
  }
  
  #leadnxt_logo {
    border-radius: 27px;
    margin: 3px 3px;
    cursor: default;
  }
  
  
  .arrow {
    border: solid #000;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-top: 7px;
    transform: rotate(225deg);
  }

  .arrow1 {
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-top: 7px;
    transform: rotate(225deg);
  }


  
  .down {
    transform: rotate(45deg);
  }
 
  
  
  .dropdown-menu {
    list-style: none;
    
    border-radius: 79px;
    padding: 10px;
    /* margin-left: 17px; */
    margin-top: 43px;
    border-radius: 7px;
    background: white;
    width: 189px;
    position: absolute;
    border: none;
    
  }
  
  .dropdown-menu li {
    padding-top: 8px;
    padding-left: 3px;
    color: #1991eb;
    background: #fff;
    cursor: pointer;
    font-weight: bold;
    word-wrap: break-word;
    font-family: sans-serif;
  }

  .approach-class {
    font-weight: bold; /* Add any other common styles */
    margin-top: 7px;
    font-size: medium;
    word-wrap: break-word;
    font-family: sans-serif;
  }
  
  .cold {
    color: #0000fffa; /* Match the color of 'cold' */
    margin-top: 7px;
  }
  
  .approaching {
    color: #000000fc; /* Match the color of 'Approaching' */
    margin-top: 7px;
  }
  
  .replied {
    color: #fa31eb; /* Match the color of 'Replied' */
    margin-top: 7px;
  }
  
  .interested {
    color: #008000fc; /* Match the color of 'Interested' */
    margin-top: 7px;
  }
  
  .notinterested {
    color: #ff0000fa; /* Match the color of 'Not Interested' */
    margin-top: 7px;
  }
  
  