/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.ad_leadnxt_logo {
  margin-left: -9px;
  width: 123px;
  height: 18px;
  top: 22px;
  left: 14px;
  position: absolute;
}

.save_contact_btn {
  border-radius: 3px;
  border-radius: 5px;
  background-color: #1991eb;
  /* padding: 8px; */
  width: 134px;
  color: white;
  /* margin-right: 10px; */
  width: 236px;
  height: 40px;
  top: 51px;
  left: 18px;
  position: absolute;
  /* font-family: Inter; */
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border: 1.5px solid #1991EB;

}

.tag_btn {
  border-radius: 3px;
  border: 1px solid #1991eb;
  padding: 2px;
  width: 65px;
  color: #1991eb;
  font-size: 16px;
  letter-spacing: 0.3px;
  width: 40px;
  height: 40px;
  top: 50px;
  left: 265px;
  border-radius: 5px;
  position: absolute;
  border: 1.5px solid #D3DAE3;
}

.cont_img {
  margin-top:2px;
  margin-left: -42px;
  padding: 3px 1px 3px 1px;
  position: absolute;
}

.contact_span {
  width: auto;
  /* height: 25px; */
  margin-top: 1px;
  margin-left: 61px;
  /* position: absolute; */
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7B8D;
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  width: 297px;
}

.globe {
  width: 19px;
  height: 23px;
  margin-left: -43px;
}

.tag_option {
  height: 25px;
  border-radius: 5px;
  border: 1px;
  position: sticky;
  border: 1px solid #2798EC;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #3771C8;
  background: #eff1f4;
  padding: 0px 7px 1px 5px;
  width: auto;
  margin-bottom: 3px;
  margin-left: 7px;
}

.list-group-div {
  bottom: 20px;
  padding: 12px;
  /* margin-top: -90px; */
  width: 385px;
  height: 56px;
  /* top: 238px; */
  /* left: -62px; */
  border-radius: 5px;
  position: relative;
  border: 1.5px solid rgb(211, 218, 227);
}

.ad_input_text {
  padding: 17px;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.3px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px;
  width: 330px;
  height: 36px;
  top: -25px;
  left: 23px;
  border-radius: 5px;
  border: 1.5px solid rgb(211, 218, 227);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px 0px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  position: absolute;
  margin-top: 35px;
  color: #949FB1;
  padding-top: 5px;
}

.social_media_opt {
  width: 23px;
  height: 23px;
  margin-left: -1px;
  padding: 1px 1px 1px 1px;
}

.section_box {
  width: 391 px;
  height: 336px;
  /* margin-top: -306px; */
  position: relative;
  z-index: 100;
  background-color: #fff;
  transform: translateX(100%);
  transition: all 0.6s ease-out;
}

.setting_ul li img {
  margin-left: -35px;
  color: #1991EB;
  width: 18px;
  height: 17px;
  margin-right: 59px;
  margin-top: 4px;
  position: absolute;
}

.setting_ul li {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  padding-bottom: 8px;
  padding-top: 6px;
  padding-left: 61px;
  color: #6F7B8D;
}

.setting_li {
  text-transform: capitalize;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid rgb(189, 189, 189);
}

.dropdown-container {
  width: 280px;
  height: 36px;
  /* margin-top: 6px; */
  margin-left: 8px;
  border-radius: 5px;
  color: #D3DAE3;
  background-color: #FFFFFF;
  border: 1.5px solid #D3DAE3;
  font-weight: 500;
  color: #00000080;
  padding-top: 4px;
  padding-left: 18px;
}

.manage_taglist {
  margin-top: -236px;
  margin-left: 4px;
  position: fixed;
  list-style-type: none;
  z-index: 1000;
  background-color: white;
  transition: all 0.6s ease-out;
  width: 339px;
  height: 93px;
  top: 368px;
  left: 14px;
  border-radius: 5px;
  border: 0.5px solid #999999BF;
}
.selectedTags{
  margin: 2px;
  width: -moz-fit-content;
  width: fit-content;
  height: 25px;
  left: 42.75px;
  border-radius: 4px;
  background: rgb(232, 238, 244);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  padding: 3px 6px;
  white-space: nowrap;
  display: inline-block;
}

.manage_taglist .tag_create_input {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  width: 314px;
  height: 31px;
  top: 7px;
  left: 13px;
  border-radius: 5px;
  position: absolute;
  border: 1px solid #D3DAE3;
  display: flex;
  overflow: hidden;

}


.tag_cross_btn {
  position: relative;
  width: 19px;
  height: 20px;
  top: -28px;
  left: 257px;

}

.tag_cancel_btn,
.tag_save_btn {
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  width: 101.64px;
  height: 30px;
  top: 46px;
  left: 13px;
  border-radius: 5px;
  border: 1px;
  color: #026DB3;
  background: #FFFFFF;
  border: 1px solid #026DB3;
  position: absolute;
  cursor: pointer;
}

.tag_save_btn {
  background-color: #026DB3;
  color: #FFFFFF;
  left: 119px;

}

.new_tag_list {
  display: block;
  justify-content: space-between;
  margin-top: -20px;
  padding: 13px;
}

.tagList_opt {
  width: 113 px;
  height: 18px;
  top: 194px;
  left: 19 px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #4E5665;
  width: 122 px;
  height: 25px;
  top: 190px;
  left: 14px;
  border-radius: 5px;
  background: #E8EEF4;
  padding: 0px 4px;
  text-transform: capitalize;
  margin: 2px;
}

.tag_opt {
  margin-right: 5px;
  width: auto;
  height: 22px;
  left: 42.75px;
  border-radius: 5px;
  background: rgb(232, 238, 244);
  font-size: 12px;
  font-weight: 700;
  line-height: 11px;
  letter-spacing: 0.2px;
  text-align: center;
  padding: 5px 6px;
  white-space: nowrap;
}

.sub_type {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: right;
  text-transform: capitalize;
  color: #9DA7B8;
  padding-top: 5px;
  cursor: pointer;
}

/*tags suggestion section */
.suggestionList {
  z-index: 999;
  position: absolute;
  width: 314px;
  height: 114px;
  top: 37px;
  left: 13px;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #D3DAE3;
  list-style: none;
  color: #D3DAE3;
  background: #FFFFFF;
}

.suggestionList input[type="checkbox"] {

  width: 15px;
  height: 20px;
  left: -5px;
  top: 6px;

}

.suggestionList label .suggestion {
  width: 103px;
  height: 17px;
  left: 24px;
  position: absolute;
  /* font-family: Inter; */
  font-size: 14px;
  font-weight: 450;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #808080;
  top: 7px;
  text-transform: capitalize;
  border-radius: 2px;
}

.suggestionList {
  overflow-x: hidden;
  overflow-y: scroll;
}

/* Width of the scrollbar */
::-webkit-scrollbar {
  width: 2px;
}

/* Track (background) of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* You can set your desired color */
  margin-right: 12px;
  margin-bottom: 2px;
}

/* Handle (thumb) of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #888;
  /* You can set your desired color */
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6F7B8D;
  /* You can set your desired color */
  border-radius: 2px;

}

.suggestionList ul li {
  padding: 5px;
}

/* Hide default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
}

/* Style the custom checkbox container */
.custom-checkbox {
  position: relative;
  padding-left: 30px;
  /* Adjust as needed for checkbox size */
  cursor: pointer;
}

/* Style the checkmark */
.checkmark {
  position: absolute;
  height: 13px;
  width: 12px;
  border: 1px solid #808080;
  ;
  margin: 8px 0px 0px -26px;
  border-radius: 2px;
}

/* Show the tick mark when checkbox is checked */
.custom-checkbox input:checked~.checkmark:after {
  content: '';
  position: absolute;
  display: block;
  left: 4px;
  top: 1px;
  width: 2px;
  height: 6px;
  border: solid #808080;
  border-width: 0 2px 2px 0;
  transform: rotate(39deg);
}

/* profile list styling */
.profileList{
  width: 169px;
  /* height: 119px; */
  height: auto;
  top: 82px;
  left: 195px;
  border-radius: 0px 0px 5px 5px;
  border: 1px;
  border: 1px solid #D3DAE3;
  position: absolute;
  z-index: 999;
  background: #ffff;
}
.profileList ul {
  list-style: none;
}
.profile{
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000080;
}

.profile_cancel_btn,.profile_save_btn{
  color: #026DB3;
  background: #FFFFFF;
  position: absolute;
  cursor: pointer;
  top: 122px;
  left: 9px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  width: 87.92px;
  height: 23.85px;
  border-radius: 5px;
  border: 1px solid rgba(2, 109, 179, 1);
}
.profile_save_btn{
  background-color: #026DB3;
  color: #FFFFFF;
  left: 101px;
  height: 20.5px;
  width: 87.92px;
   height: 23.85px;
    border-radius: 5px
}
.profile_list{
  position: absolute;
  list-style: none;
  color: #D3DAE3;
  background: #FFFFFF;
  border: 1.5px solid #D3DAE3;
  width: 344px;
  height: auto;
  top: 29px;
  border-radius: 0px 0px 0px 0px;
  overflow-y: scroll;
  border-bottom: none;
}
.profile_option{
  color: rgba(0, 0, 0, 0.5);
  margin: 8px 0px 10px 10px;
  width: 49px;
  height: 8px;
  /* font-family: Inter; */
  font-size: 10px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
/* designation box styling */
.createDesignationBox{
  width: 345px;
  height: 79px;
  top: 112px;
  left: 18px;
  border-radius: 5px 5px 5px 5px;
  position: absolute;
  border: 1px solid rgba(211, 218, 227, 1);
  background: #fff;
  list-style: none;
}
.createList{
  width: 344px;
    height: 32px;
    top: -1px;
    left: 0px;
    opacity: 0.6;
    position: absolute;
    color: rgb(148, 159, 177);
    outline: none;
    border: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid rgba(211, 218, 227, 1);
    border-radius: 5px 5px 0px 0px;
    padding: 5px;
}
.overlay{
  width: 400px;
    /* height: 325px; */
    height: 100%;
    border-radius: 9px;
    border: 1px;
    opacity: 0.45px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: -195px;
    top: -49px;
    z-index: 1000;
}


/* hover effect on email  */
.detail_list{
  width: auto;
  margin-top: 1px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #6F7B8D;
  list-style: none;
  display: flex;
  justify-content: space-between;

}
.detail_list_background{
  background: #F3EDFF;
  cursor: pointer;

}

